exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-oracles-iching-readings-tsx": () => import("./../../../src/pages/oracles/iching_readings.tsx" /* webpackChunkName: "component---src-pages-oracles-iching-readings-tsx" */),
  "component---src-pages-oracles-iching-tsx": () => import("./../../../src/pages/oracles/iching.tsx" /* webpackChunkName: "component---src-pages-oracles-iching-tsx" */),
  "component---src-pages-oracles-runes-readings-tsx": () => import("./../../../src/pages/oracles/runes_readings.tsx" /* webpackChunkName: "component---src-pages-oracles-runes-readings-tsx" */),
  "component---src-pages-oracles-runes-tsx": () => import("./../../../src/pages/oracles/runes.tsx" /* webpackChunkName: "component---src-pages-oracles-runes-tsx" */),
  "component---src-pages-oracles-tarot-readings-tsx": () => import("./../../../src/pages/oracles/tarot_readings.tsx" /* webpackChunkName: "component---src-pages-oracles-tarot-readings-tsx" */),
  "component---src-pages-oracles-tarot-tsx": () => import("./../../../src/pages/oracles/tarot.tsx" /* webpackChunkName: "component---src-pages-oracles-tarot-tsx" */),
  "component---src-pages-oracles-yesno-tsx": () => import("./../../../src/pages/oracles/yesno.tsx" /* webpackChunkName: "component---src-pages-oracles-yesno-tsx" */),
  "component---src-pages-privacypolicy-tsx": () => import("./../../../src/pages/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-privacypolicy-tsx" */)
}

